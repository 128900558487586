import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import ContactGlobal from "components/ContactGlobal"

import { Location } from "page_components/contact"

const Contact = () => {
  const title = "Kontakt"
  return (
    <Layout
      seo={{
        title: title,
        description:
          "Skontaktuj się z naszymi ekspertami od nieruchomości premium. Znajdziemy dla Ciebie idealną nieruchomość.",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <ContactGlobal header="Dołącz do grona zadowolonych klientów" />
      <Location />
    </Layout>
  )
}

export default Contact
